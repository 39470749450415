<template>
  <div class="container">
    <a-card shadow="never" class="my-card">
      <p style="display: flex; justify-content: space-between">
        <span style="color: lightgray">首页 / 签署服务协议</span>
        <a @click="$router.go(-1)">返回</a>
      </p>
      <p style="font-weight: bold; font-size: 20px; margin-top: 10px">
        签署服务协议
      </p>
      <a-textarea
        v-model="common_content"
        placeholder="请输入协议内容"
        :rows="26"
      ></a-textarea>
      <p style="color: red">注意：您需要阅读完全部协议后，方可进行签署</p>
      <div style="display: flex; justify-content: center; margin-top: 60px">
        <a-button type="primary" @click="$router.push('sign-agreement-next')">
          我已阅读并同意以上服务协议
        </a-button>
      </div>
    </a-card>
  </div>
</template>

<script>
export default {
  name: 'sign-agreement',
  data() {
    return {
      form: this.$form.createForm(this, { name: 'form' }),
      common_content:
        '海纳支付跨境收款协议\n' +
        '《海纳支付跨境收款协议》（以下简称 本协议 ）由 “ ” 海纳支付香港有限公司 Haina\n' +
        'Payment HK CO., Limited（以下简称 我司 ）和您签订，本协议约定了我司向您提供跨境\n' +
        '收款服务、双方权利义务及其他事项，为了保障您的权益，请在使用该服务前，详细阅读\n' +
        '本协议的所有内容，特别是加粗部分。当您在我司的网络页面上点击本协议的勾选框（勾\n' +
        '选框后书写“海纳支付跨境收款协议收款协议 或类似文字，且页面上同时列明本协议的内 ”\n' +
        '容或者可以有效展示本协议内容的链接），或以其它合理方式表明您与我司订立了本协议\n' +
        '时，即表明本协议在您与我司之间成立并生效。\n' +
        '一、声明与承诺\n' +
        '1.为了向您提供服务所需，我司会依照合法、正当、必要的原则收集您的信息，并按照\n' +
        '《跨境收款隐私政策》中的说明使用、保护、保存及对外提供您的信息。\n' +
        '2.您使用本跨境收款服务，应基于真实货物贸易背景，我司会对您提供的交易信息等材料\n' +
        '进行核查，若未通过则不能成为我司用户，我司将不会为您提供以下服务。\n' +
        '3.我司不会采集您（作为个人用户）的宗教信仰、基因、指纹、血型、疾病和病史信息以\n' +
        '及法律、行政法规规定禁止采集的其他个人信息。\n' +
        '4.我司承诺尽商业上的合理努力采用安全技术和程序保障用户信息安全，确保上述技术和\n' +
        '程序安全标准不低于国家法律法规的要求，以防信息的丢失、不当使用等情况的发生，并\n' +
        '采取合理措施保障向第三方提供用户信息的准确性。\n' +
        '5.在您注册成为海纳支付用户或您以我司允许的其他方式实际使用本服务，即您使用本服\n' +
        '务，则表示您已充分阅读、理解并接受本协议的全部内容，同意遵循本协议之所有约定。\n' +
        '6.您同意，我司有权对本协议内容进行变更，并以在本网站（我司网站）或其他适当地方\n' +
        '公告的方式予以公布，无需另行单独通知您；您在本协议内容公告变更后继续使用本服务，\n' +
        '表示您已充分阅读、理解并接受变更后的协议内容；若您不同意修改后的协议内容，请您\n' +
        '勿进行下一步操作，您可以联系我司企业以了解更多信息。\n' +
        '7.您知悉您本人和您使用海纳支付服务将受到 适用法 强制性规定的约束。 “ ” 特此声明，您是\n' +
        '以专业或商业身份开展活动，您不是以消费者身份签署本协议以及使用海纳支付服务。您\n' +
        '需要全权负责理解和遵守您原籍国/地区的法律、法规，以及与您使用海纳支付服务相关、\n' +
        '基于账户司法管辖权可能适用于您的法律、法规，与以下监管支付服务相关的法律法规包\n' +
        '括但不限于：反洗钱和反恐怖组织融资要求、消费者保护、信息保护、反歧视、赌博、虚\n' +
        '假广告、非法销售或购买或交易任何商品或服务，和相关的进出口活动、税收或外汇交易\n' +
        '活动。\n' +
        '二、定义及解释\n' +
        '在本协议中，下列术语有下列含义：\n' +
        'a)“海纳支付跨境收款服务的提供方 指的是， ” 海纳支付香港有限公司 Haina Payment HK\n' +
        'CO., Limited，注册地址：SUITE 6 RM 1208,12/F CHEUNG SHA WAN PLAZA TOWER\n' +
        '2,833 CHEUNG SHA WAN RD KLN HONG KONG。\n' +
        'b)“跨境收款服务” 主要服务内容是作为您的收款代理人为您在境外收取结算货物贸易款项\n' +
        '资金，并通过合作银行将该等款项直接转入您指定的银行账户，或将该等款项进行汇兑并\n' +
        '将汇兑后的人民币资金转入您指定的银行账户，同时在资金处理过程中履行相应国际收支\n' +
        '申报等相关地区法律法规所规定（若有），以及合作银行操作规范的手续。如您的资金因\n' +
        '违反当地法律法规而遭到银行滞留、缓付、拒付，我公司不具有先行垫付的责任。\n' +
        'c)“合作方”指基于为您提供跨境收款服务的需求，配合我司为您提供服务的合作方，包括\n' +
        '但不限于境内外合作银行、支付机构及其他合作方。\n' +
        'd)“海纳支付”指我司通过“海纳支付”软件系统提供的在线支付与收款服务。\n' +
        'e)“提现账户”指您在海纳支付所指定的相关收款银行账户，即您接收海纳支付账户余额的\n' +
        '银行账户。\n' +
        'f)“工作日”指的是在该账户司法管辖区内，除周六、周日和公共节假日外的工作日，在工\n' +
        '作日，该管辖区内支付的网络基础设施是开放的，且银行正常开展日常业务活动。\n' +
        'g)“用户”指如无特别说明，使用本服务的自然人（含个体工商户，以下称为 个人用户 ） “ ”\n' +
        '和企业（含事业单位、其它组织等，以下称为“ ” 企业用户 ）。如无特别说明，本协议内写\n' +
        '明 您 和 用户 的地方 均包括个人用户与企业用户，统称为 用户 。\n' +
        'h)“海纳账户”指用户按照本协议第四条第 1 款注册使用的账户。\n' +
        'i)“信息提供者”指我司采集用户信息的人和其他渠道，包括用户本人、合法拥有用户信息\n' +
        '的主体及其代理机构、媒体等。\n' +
        'j)“海纳支付客户端”指由海纳支付公司运营的，负责向用户提供全球收款、换汇及全球付\n' +
        '款业务等服务的金融信息中介服务平台。\n' +
        'k)“书面 或 书面形式 ” “ ”指如无特别说明，指以纸质或电子介质记载的、可以有形地表现其\n' +
        '所记载内容的任何形式。\n' +
        'L)“电商平台”指的是电子商务市场或第三方销售平台。\n' +
        '三、缔结方\n' +
        '1.在本协议中，因存在不同地区的收款需求，故您在不同地区的收款账户缔结方会有所不\n' +
        '同，具体名单见下表。\n' +
        '账户管辖 缔约方 地址信息\n' +
        '香港\n' +
        '海纳支付香港有限公司\n' +
        'Haina Payment HK CO.,\n' +
        'Limited\n' +
        'SUITE 6 RM 1208,12/F CHEUNG SHA WAN\n' +
        'PLAZA TOWER 2,833 CHEUNG SHA WAN RD KLN\n' +
        'HONG KONG\n' +
        '2. 服务先决条件\n' +
        '（1）您必须年满 18 岁。您必须有签订合同的完全法律行为能力。如果您代表企业或商业\n' +
        '组织使用我司服务，您必须获得根据该商业组织的格式文件或您所在国家/地区的法律合法\n' +
        '授权，有权代表组织同意这些条款和条件。您应当按照相关监管规定办理或者符合开展跨\n' +
        '境交易或使用本服务所需的资质、登记、审批和条件等，如您未办理或者未符合的，我司\n' +
        '有权单方终止向您提供服务，造成的损失由您自行承担。\n' +
        '（2）除特定情况外，您必须使用我司支付服务处理您自己的账户，而不是代表任何其他\n' +
        '人或组织进行交易；如有特殊情况，我们可能需要授权方书面授权代理人进行交易以及我\n' +
        '们可能需要的任何其他文件来协助我们履行合规义务。我司严格禁止在使用我们的服务时\n' +
        '假冒或虚假地声明与任何个人或组织的联系。\n' +
        '（3）当您的信息有更改时，请您务必及时更新以便信息保持完整和准确。我们保留随时\n' +
        '因任何原因拒绝向个人或组织提供或停止提供我司服务的权力。\n' +
        '四、 海纳账户\n' +
        '1.注册以及注销账户相关\n' +
        '为了您能安全高效地使用本服务，您须在本网站注册并取得我司分配给您的海纳账户，并\n' +
        '按照我司的规则进行身份校验。为此您同意：\n' +
        '(1) 您同意指定海纳支付作为您的代理人从市场上收取销售货物或者服务的款项资金。您认\n' +
        '可并且同意向海纳支付款项构成履行向您完成支付的义务。您同意您将向每一个市场提交\n' +
        '符合其要求的文件，用于表明海纳是您的代理人，并且要求市场将对于您的应付款项支付\n' +
        '给海纳支付。\n' +
        '(2)您应当准确、完整地提供并及时更新您的身份信息/企业信息及相关资料。如果您提供\n' +
        '的身份信息/企业信息及相关资料不合法、不真实、不准确、不完整或涉嫌侵犯他人权利或\n' +
        '不符合监管部门要求的情形，或者您没有及时配合补充相关资料，您对海纳支付服务的使\n' +
        '用可能会受到部分或全部地限制、暂停或终止。我司就上述行为不承担任何责任，造成的\n' +
        '损失由您自行承担。\n' +
        '(3)您应当准确提供并及时更新您的电子邮件地址、联系电话、联系地址、邮政编码等联系\n' +
        '方式，以便我司与您进行及时、有效的联系。您应完全独自承担因通过这些联系方式无法\n' +
        '与您取得联系致使您遭受的任何损失和增加任何费用等不利后果。您理解并同意，您有义\n' +
        '务保持您提供的联系方式的有效性，如有任何变更应及时通知我司以进行更新，同时您应\n' +
        '按我司的要求进行相关操作。\n' +
        '(4)您应及时更新资料（包括但不限于身份证、护照、工商营业执照、商业登记证等证件和\n' +
        '其他身份证明文件信息，注册、使用海纳支付账户和本服务的电子邮箱名或手机号码，与\n' +
        '海纳支付账户绑定的邮箱、手机号码等（如有））。因您未及时更新资料导致的一切后果，\n' +
        '均应由您自行承担。\n' +
        '(5)我司需要对您提交的注册信息进行审核，并在您提交注册信息后 T+2 个工作日之内审核\n' +
        '完毕，若审核通过后您会收到我司发送的邮件通知。本跨境收款协议自我司发出邮件通知\n' +
        '之日起生效，有效期一年，到期后若双方未解除协议的，本协议自动展续。\n' +
        '(6)当您不再需要使用我司提供服务时，您可以申请注销您的海纳支付相关账户，申请注销\n' +
        '账户是否通过将在 T＋2 个工作日，将通过电话、电子邮件、手机短信、网站站内信等方\n' +
        '式给您答复，请您应及时予以关注。您申请注销之后，您的资金将不能进入该账户。\n' +
        '(7)在下列情况任意情况出现时，如我司要求，您应当积极配合提供您的有效身份证明文件\n' +
        '或其他必要文件，并允许我司留存该等文件的彩色扫描件，否则您对海纳支付服务的使用\n' +
        '可能会受到部分或全部的限制、暂停或终止：\n' +
        '1)您要求变更身份信息或您的身份证明文件已过有效期的；\n' +
        '2)我司认为您使用海纳支付的行为或相关情况出现异常的；\n' +
        '3)我司认为您的身份资料存在疑点的；\n' +
        '4)我司认为有必要核对或留存您身份证明文件或其他必要文件的其他情形。\n' +
        '5)我司可以通过任何合法运营的拥有用户身份校验机制的主体对您进行身份校验。\n' +
        '2.账户安全\n' +
        '(1)您可以使用本服务是基于您按照本协议的要求注册了海纳支付账户，并已完成身份校验，\n' +
        '所以您将对使用该账户进行的一切操作负完全的责任，您同意：您应当对能够登录并使用\n' +
        '您的海纳支付账户，或验证您身份的一切信息和设备（包括但不限于海纳支付账户登录名\n' +
        '（如有）、海纳支付登录名、任何密码、身份信息、手机号码、email 地址、电脑等移动\n' +
        '终端）进行妥善保管。对于因这些信息或设备的披露、泄露、遗失或非授权使用所致的损\n' +
        '失，由您自行承担。仅就使用本服务而言，您保证不向其他任何人披露、泄露或借出前述\n' +
        '信息和设备，亦不使用其他任何人的该等信息和设备。\n' +
        '(2)基于不同用户对计算机端以及其它电子设备的不同使用习惯，我司可能在您使用具体设\n' +
        '备时设置不同的账户登录模式及采取不同的措施识别您的身份。\n' +
        '(3)您同意：如您发现本协议第四条、第 2 款、第（1）项所述的信息或设备遗失，被他人\n' +
        '冒用、盗用，未经您的合法授权被使用之情形，或发生其他可能危及到海纳支付账户安全\n' +
        '或您的用户信息安全的情形时，您应立即以有效方式通知到我司，向我司申请采取相关措\n' +
        '施。我司收到您的申请或我司有合理理由认为针对本服务的操作并非出自于您本人时，我\n' +
        '司可以暂时中止提供相关服务。您不可撤销地授权我司可以根据自行判断将前述情况同步\n' +
        '给所有利害关系人，以保障您和他人的合法权益。\n' +
        '(4)您确保在持续登录我司的网站时段结束时，以正确步骤退出登录并离开网站。我司不能\n' +
        '亦不会对因您未能遵守本款约定而发生的任何损失、损毁及其他不利后果负责。\n' +
        '(5)您应自行对您的海纳支付账户负责，只有您本人方可登录、使用该账户。该账户不可转\n' +
        '让、不可赠与、不可继承。\n' +
        '3.保密条款\n' +
        '（1）协议一方在履行本协议期间对于所获知的另一方的商业和技术信息及秘密（商业和\n' +
        '技术秘密包括但不限于交易手续费和服务年费的金额及支付方式、结算方式、接口技术、\n' +
        '安全协议及证书等）负有保密义务，任何一方不得将已获知的另一方的商业和技术秘密泄\n' +
        '露给任何第三方。\n' +
        '（2）任何一方只能将保密信息和其内容用于本协议项下的目的，不得用于其他目的，本\n' +
        '款的约定不适用于下列保密信息：a)从披露方获得时，已是公开的；b)从披露方获得前，\n' +
        '接受方已获知的；c)从有正当权限并不受保密义务制约的第三方获得的。\n' +
        '（3）本协议双方因下列原因披露保密信息，不受前款限制：a) 向本协议双方的董事、监\n' +
        '事、高级管理人员和雇员及其聘请的会计师、律师、咨询公司披露；b) 因法律、法规的强\n' +
        '制性规定而披露。\n' +
        '（4） 协议双方应当促使其负责披露信息的人员亦严格遵守本条约定。\n' +
        '五、海纳支付服务规则及相关授权\n' +
        '1.收款服务\n' +
        '(1)为了您可以正常使用跨境电商收款服务，您需要首先授权我司在电商平台为您的店铺实\n' +
        '施绑定（店铺绑定申明见附件一）。\n' +
        '(2)店铺结算资金入账到达您的海纳支付账户所需的时间不在我司的控制之中。结算资金通\n' +
        '常在 1-3 个工作日内收到，最多可能需要 5 个工作日或更长时间。如果在相关平台的指示\n' +
        '结算成功后 5 个工作日内未收到入账资金的，我司可根据您的要求代表您进行内部调查，\n' +
        '如因此所产生的相关成本和费用均由您承担（如有）。\n' +
        '(3)我司在收到结算资金后，在不迟于下一个工作日内以电子邮件形式及时通知您的资金入\n' +
        '账情况。\n' +
        '(4)您使用本服务进行跨境收款，应基于真实跨境电商交易为背景，并根据我司的要求提供\n' +
        '身份信息、交易信息等材料进行核验。如果您的交易出现异常，或者银行对您的交易或者\n' +
        '账户产生疑问而暂时滞留您的款项，则我司将根据银行的指令要求您提供进一步的信息及\n' +
        '资料。\n' +
        '(5)本服务下结算资金结汇所适用的汇率为海纳支付香港有限公司合作银行所提供的外汇参\n' +
        '考牌价。\n' +
        '(6)您确认并同意，您将独自承担本服务下结算资金的转账所产生的，由海纳支付香港有限\n' +
        '公司合作方所收取的所有手续费用，具体费用标准以相关业务展示为准。\n' +
        '(7)我司非银行或信贷机构，对您在海纳支付账户余额不支付利息。通过接受本条款和条件，\n' +
        '您确认海纳支付有权保留对海纳支付银行账户中持有的任何资金产生的任何利益。\n' +
        '(8)您需要自行承担您使用我司服务期间的货币贬值、汇率波动及收益损失等风险，您仅在\n' +
        '我司代为收取或代为支付款项（不含被有权机关冻结、止付或其他限制的款项）的金额范\n' +
        '围内享有对该等款项支付、提现的权利，您对所有代为收取或代为支付款项（含被有权机\n' +
        '关冻结、止付或受限制的款项）产生的收益（如利息和其他孳息）不享有权利。为了更好\n' +
        '地向您提供服务，我司就该代为收取或代为支付款项产生的收益（如利息和其他孳息）享\n' +
        '有所有权。\n' +
        '2.提现服务\n' +
        '(1)我司的提现服务一般为公对公业务，不能用作私人之间进行资金转账。我司有权拒绝执\n' +
        '行任何向不属于您或者您不是该账户的持有人的银行账户的结算指示。您提现请求的最终\n' +
        '实现，将根据银行的制度或者要求而会有所改变。\n' +
        '(2)若您的海纳支付账户状态为正常，您才可以提交提现申请请求，指示我司将部分或全部\n' +
        '余额（扣除费用后）提现至您要求的账户。关于每笔提现的数额限制，请遵守《国家外汇\n' +
        '管理局关于规范银行卡境外大额提取现金交易的通知》等相关国家法律法规规定。\n' +
        '(3)您必须确保您的提现账户信息准确无误。因您提供不准确的提现账户信息而导致的资金\n' +
        '转移错误，我司对此概不负责。如果由于您提供的不准确的提现账户信息而导致付款被拒\n' +
        '绝，您将可能承担相关费用（如有）。\n' +
        '(4)若发生违反合规要求或含有误导性的提现申请，我司将采取合理措施，协助您进行追查，\n' +
        '并在合理可行的情况下追回此类款项，但对于任何无法追回的款项，我司概不负责。\n' +
        '(5)一旦您向我司提交请求以执行提现服务并同意从海纳支付账户中扣除提现金额和所有适\n' +
        '用费用，该提现操作是不可撤销的，您将无法退还已转入您的提现账户中的资金。\n' +
        '(6)海纳支付将向您收取一定的跨境收款提现服务费，具体的服务费规则以您的海纳支付跨\n' +
        '境收款账户中实时显示的相关内容为准，但双方有另行书面约定的情况除外。若海纳支付\n' +
        '拟调整或更新有关收款服务费规则的，将在您的海纳支付跨境收款账户中发布调整更新后\n' +
        '的费率，您继续使用海纳支付提供的跨境收款服务则视为您无条件同意海纳支付相应的调\n' +
        '整变更。您进一步知晓并同意，海纳支付将在结算资金扣除按照上述费率计算的提现服务\n' +
        '费后汇至您指定的银行账户。\n' +
        '(7)我司保留从您的海纳支付账户的结算款中扣除您应付给我司的任何费用的权力，包括但\n' +
        '不限于所有根据本协议需要支付的费用。\n' +
        '(8)我司收到的提现请求将在 0-3 个工作日内以当日的合作银行的货币汇率进行处理。\n' +
        '(9)如果您的提现请求被银行拒绝，或者迟延，我司不承担预先支付的责任。\n' +
        '(10)我司在发现本服务被异常使用，或对服务的使用有合理疑义，或对服务的使用有违反\n' +
        '法律规定或本协议约定，或收到国家外汇管理局等相关国家部门调查通知时，有权拒绝您\n' +
        '的提现申请，并通过电话、电子邮件、手机短信、网站站内信等方式通知您，您应及时予\n' +
        '以关注。\n' +
        '3.您同意，基于运行和交易安全的需要，我司可以暂时停止提供全部或部分服务及其功能，\n' +
        '或提供新的功能或基于政策、业务变化调整已有的功能。在任何服务及功能减少、增加或\n' +
        '者变化时，若您仍使用本服务，则表示您仍然同意本协议或者变更后的协议。\n' +
        '六、 相关权利义务使用限制\n' +
        '1.您在使用本服务时应遵守各国/地区相关法律法规，不将本服务用于任何非法目的（例如\n' +
        '用于洗钱、诈骗等），且不以任何非法方式使用本服务。\n' +
        '2.您理解并同意，我司不对因下述任一情况导致的任何损害赔偿承担责任，包括但不限于\n' +
        '利润、商誉、数据等方面的损失或其他无形损失的损害赔偿（无论我司是否已被告知该等\n' +
        '损害赔偿的可能性）：\n' +
        '(1)如果您违反本协议的明文规定及精神，我司可能对您暂停、中断或终止提供本服务或其\n' +
        '任何部分，且我司会在法律允许的范围内继续持有、保存您的用户信息与使用本服务的记\n' +
        '录。\n' +
        '(2)在发现本服务被异常使用，或对服务的使用有合理疑义，或对服务的使用有违反法律规\n' +
        '定或本协议约定之虞时，我司有权不经通知先行暂停或终止您对于本服务使用，并通过电\n' +
        '话、电子邮件、手机短信、网站站内信等方式通知您，您应及时予以关注。具体可能导致\n' +
        '暂停或终止服务的情形包括但不限于：\n' +
        '1)根据本协议的约定；\n' +
        '2)根据法律法规及法律文书的规定；\n' +
        '3)根据有权机关的要求；\n' +
        '4)您使用海纳支付服务的行为涉嫌违反国家法律法规及行政规定；\n' +
        '5)我司自行依据合理判断认为可能产生风险的；\n' +
        '6)您遭到他人投诉，且对方已经提供了一定证据的；\n' +
        '如您申请恢复服务、解除上述服务中止或限制，您应按我司要求如实提供相关资料、您的\n' +
        '身份证明及我司要求的其他信息或文件，以便我司进行核实，且我司有权依照自行判断来\n' +
        '决定是否同意您的申请。您应充分理解您的申请并不必然被允许。您拒绝如实提供相关资\n' +
        '料及身份证明的，或未通过我司审核的，则您确认我司有权长期对该等账户停止提供服务\n' +
        '且长期限制该等产品或者服务的部分或全部功能。\n' +
        '七、系统中断或故障\n' +
        '我司系统因下列状况无法正常运作，使您无法使用各项服务时，我司不承担损害赔偿责任，\n' +
        '该状况包括但不限于：\n' +
        '(1)我司在本网站公告之系统停机维护或升级期间。\n' +
        '(2)电脑软硬件或电信设备出现故障导致不能进行数据传输。\n' +
        '(3)因台风、地震、海啸、洪水、停电、战争、恐怖袭击等不可抗力之因素，造成我司系统\n' +
        '障碍不能执行业务。\n' +
        '(4)由于黑客攻击、电信部门技术调整或故障、网站升级、第三方方面的问题等原因而造成\n' +
        '的服务中断或者延迟。\n' +
        '八、 责任范围及责任限制\n' +
        '1.我司仅对本协议中列明的责任承担范围负责。\n' +
        '2.您理解并同意，因我司根据本协议并依法向第三方提供您的用户信息产生的任何后果和\n' +
        '风险应由您自行承担，包括但不限于您的贷款 申请、支付申请被拒绝，您无法获得更优惠\n' +
        '的交易条件、您的相关信息被他人不法利用。\n' +
        '3.您理解并同意，我司提供的服务是符合国家法律法规规范，并且受到中国国家外汇管理\n' +
        '局等相关机关的监督管理。在我司定期及其不定期受到监管和调查时，您需配合提供相关\n' +
        '资料。如若您未提供或者拒绝提供导致我司受到处罚的，您需要承担因此而造成我司的全\n' +
        '部责任。\n' +
        '4.为了符合相关法律法规、监管要求，以及为您提供本服务所需，您同意我司在与履行监\n' +
        '管要求的义务相关、提供本服务所需、或便利本服务提供之目的，向合作银行和/或相关部\n' +
        '门提供您的身份信息和/或交易信息，或为您进行相应的基本信息填报、以便完成信息申报、\n' +
        '监管报告并为您提供相关服务。\n' +
        '5.您理解并同意就征信服务：信息提供者向我司提供您的不良信息时，信息提供者应当对\n' +
        '您进行通知（依照法律、行政法规规定公开的不良信息除外），但信息提供者未履行通知\n' +
        '义务，或者已履行通知义务但未通知到您并不妨碍这些不良信息的提供，对此我司不承担\n' +
        '任何责任。\n' +
        '6.在法律允许的情况下，我司对于与本协议有关或由本协议引起的任何间接的、惩罚性的、\n' +
        '特殊的、派生的损失（包括业务损失、收益损失、利润损失、商誉损失、使用数据或其他\n' +
        '经济利益的损失）均不负有任何责任，即使事先已被告知此等损失的可能性。\n' +
        '7. 您承诺，您将自行处理您与您的用户（如有）而产生的任何纠纷或争议，且您将就任何\n' +
        '该等纠纷或争议而导致的我司或其任何雇员、官员、代理人或关联公司所遭受的任何损失\n' +
        '或损害向我司支付或其任何雇员、官员、代理人或关联公司进行补偿并使其免受其害。\n' +
        '8.您承诺不会将服务项下的任何权利、义务转让给第三方。\n' +
        '9.我司不介入您与平台用户、交易对象的合作关系以及任何合作上的权利义务关系，也无\n' +
        '义务承担您与任何合作方的纠纷或损失赔偿责任、法律责任。\n' +
        '10.除本协议另有规定外，在任何情况下，我司对本协议所承担的违约赔偿责任总额不超过\n' +
        '向您收取的当次服务费用总额。\n' +
        '九、完整协议\n' +
        '1.为了您更好地使用海纳支付服务，您还需要阅读并遵守海纳支付网站公示的其它规则，\n' +
        '请您务必审慎阅读、充分理解各条款内容，特别是免除或者限制服务方责任的条款。\n' +
        '2.本协议由本协议条款与本网站公示的各项规则组成，相关名词可互相引用参照，如有歧\n' +
        '义，以本协议条款为准。您对本协议理解和认同，即对本协议所有组成部分的内容理解并\n' +
        '认同，一旦您使用本服务，您和我司即受本协议所有组成部分的约束。\n' +
        '3.本协议部分内容被有管辖权的法院认定为违法或无效的，不因此影响其他内容的效力。\n' +
        '十、 商标、知识产权、专利的保护\n' +
        '1.我司及关联公司所有系统及本网站上所有内容，包括但不限于著作、图片、档案、资讯、\n' +
        '资料、网站架构、网站画面的安排、网页设计、中英文表示、logo，均由我司或我司关联\n' +
        '公司依法拥有其知识产权，包括但不限于商标权、专利权、著作权、商业秘密权利等。\n' +
        '2.非经我司或我司关联企业书面同意，任何人不得在任何国家/地区擅自使用、修改、复制、\n' +
        '公开传播、改变、散布、发行或公开发表本网站程序或内容。\n' +
        '3.本合同项下，您所销售的商品或提供的服务（如有）不得侵犯第三方的知识产权，由此\n' +
        '产生的侵权责任，需由您自行承担。\n' +
        '4.尊重知识产权是您应尽的义务，如有违反，您须承担损害赔偿责任，赔偿我司全部损失。\n' +
        '十一、争议处理\n' +
        '本协议受中国香港法律管辖，并根据中国香港法律进行解释。如果双方之间出现与本协议\n' +
        '相关的任何争议、索赔或分歧，包括本协议的违约、终止或有效性，或与根据本协议客观\n' +
        '确定的任何事项的确定相关的任何争议、索赔或分歧，应首先尝试协商友好解决。协商不\n' +
        '成的，双方同意交由香港法院，按照香港法院现行仲裁规则进行仲裁并最终解决。\n' +
        '附件一、店铺绑定条款\n' +
        '若您向海纳支付提供上述授权信息或继续使用跨境收款服务，即被视为授权海纳支付使用\n' +
        '上述授权获取与您的跨境收款交易相关的交易背景材料以满足相关合规要求。海纳支付承\n' +
        '诺将对使用该等授权所收集的交易背景材料严格保密，且不会将该等授权用于前述满足合\n' +
        '规要求之外的任何其他用途。您可以在停止使用跨境收款服务时，通知海纳支付以终止上\n' +
        '述授权。\n' +
        '为此，海纳支付承诺仅在您授权的范围之内获取且仅为向您提供跨境收款服务之目的而使\n' +
        '用该等交易背景材料。若因海纳支付过错而超出授权范围获取交易背景材料或使用交易背\n' +
        '景材料并因此对您造成经济损失的，海纳支付将对您所遭受的该等实际经济损失和支出予\n' +
        '以赔偿，包括但不限于您为保护该等法律权利或进行相应的抗辩而支出的费用、诉讼或仲\n' +
        '裁费、律师费及保全费等。\n' +
        '为了保障您的权益，请在使用该服务前，详细阅读本协议的所有内容，特别是加粗部分。\n' +
        '当您在我司的网络页面上点击本协议的勾选框（勾选框后书写“海纳支付跨境收款协议收款\n' +
        '协议 或类似文字，且页面上同时列明本协议的内容或者可以有效展示本协议内容的链接） ” ，\n' +
        '或以其它合理方式表明您与我司订立了本协议时，即表明本协议在您与我司之间成立并生\n' +
        '效。\n' +
        '海纳支付香港有限公司\n' +
        'Haina Payment HK CO., Limited',
    };
  },
};
</script>

<style scoped lang="scss">
.container {
  box-sizing: border-box;
  padding: 24px;
  background: #f0f2f5;
  height: 100%;
  .my-card {
    height: 100%;
    margin-bottom: 20px;
  }
}
</style>
